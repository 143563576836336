<template>
  <configuration-set
    v-if="!!getConfigurationSet()?.courier"
    :components="components"
    :speditor="speditor"
    :current-component="currentComponent"
    :is-side-bar-active="isAuthorized"
    :loading="loading"
    @@changeComponent="changeComponent"
    @@save="saveConfiguration"
  >
    <template v-slot:content>
      <ServiceAuthorization
        v-show="currentComponent === 'ServiceAuthorization'"
        :is-new="isNew"
        :is-authorized="isAuthorized"
      />
      <AdditionalServices
        v-show="currentComponent === 'AdditionalServices'"
        :is-new="isNew"
      />
      <ContentAndComments
        v-show="currentComponent === 'ContentAndComments'"
        :is-new="isNew"
      />
    </template>
  </configuration-set>
</template>

<script>
import ServiceAuthorization from '@/views/Speditors/Allegro/Forms/ServiceAuthorization.vue';
import AdditionalServices from '@/views/Speditors/Allegro/Forms/AdditionalServices.vue';
import ContentAndComments from '@/views/Speditors/Allegro/Forms/ContentAndComments.vue';
import { Allegro } from '@/constants/Speditors/Allegro/configuration/Allegro';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ConfigurationSet from '@/components/shared/ConfigurationSet.vue';
import configurationMixin from '@/mixins/configurationMixin';

export default Vue.extend({
  name: 'Allegro',
  components: {
    ConfigurationSet,
    ServiceAuthorization,
    AdditionalServices,
    ContentAndComments,
  },
  mixins: [configurationMixin],
  data: () => ({
    isNew: true,
    speditor: 'Allegro',
    organizationId: null,
    currentComponent: 'ServiceAuthorization',
    components: [{ code: 'ServiceAuthorization', name: 'courierSet.serviceAuthorizationName' },
      { code: 'AdditionalServices', name: 'courierSet.additionalServices' },
      { code: 'ContentAndComments', name: 'courierSet.contentAndComments' }],
  }),
  computed: {
    ...mapGetters('spedition', ['getAllegroAuthorization']),
    isAuthorized() {
      return !!this.getAllegroAuthorization()?.accessToken || !!this.getConfigurationSet().token;
    },
  },
  methods: {
    saveConfiguration() {
      if (!this.getConfigurationSet().token) {
        this.setAuthorizationData();
      }
      this.save();
    },
    setAuthorizationData() {
      this.getConfigurationSet().token = this.getAllegroAuthorization().accessToken;
      this.getConfigurationSet().refreshToken = this.getAllegroAuthorization().refreshToken;
    },
    setNewConfiguration() {
      this.setConfigSet({ response: Allegro });
    },
  },
});
</script>
